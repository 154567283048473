import logo from './assets/homer-martin-agency-logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We are pleased to announce that the Homer M. Martin Agency, Inc. is now part of the Francis J. Craig Agency, Inc.
        </p>
        <p>
          Call them at <a className="App-link" href="7249355753">724-935-5753</a> for assistance with your policy, or click the link below to be directed to their website for more information.
        </p>
        <a
          className="App-link"
          href="https://craigins.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Francis J. Craig Agency, Inc.
        </a>
      </header>
    </div>
  );
}

export default App;
